<template>
<div 
@click="$emit('onSelectItemChange', index)"
class="card">
    <img  class="card-icon"    :src="require(`@/assets/icons/${data.icon}`)" />
   <div
   class="card-description"
   >
  <div>
    <p class="card-desctiption-title bold-text">{{this.data.title}}</p>
   <p class="card-description-content light-text" >{{this.data.description}}</p>
  </div>


   </div>
   <div
   class="checkbox"
   >    
   <input 
   class="radio-input"
   type="radio"
    :checked="data.active"
   />

   </div>
</div>
</template>


<script>
    export default {
        props:['data', 'index'],
        emits:["onSelectItemChange"],
    }
</script>
<style lang="scss" scoped>
 @import "@/sass/_variables.scss";
 @import "./SelectCard.scss"
    
</style>